import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import Layout from '../components/layout'
import Button from '../components/button'
import SEO from '../components/seo'
import PhotoGalleryComponent from '../components/photo-gallery-component'


const GalleryPage = (props) => {
  const data = useStaticQuery(graphql`
    query GalleryPhotos {
      contentfulPhotoGallery(contentful_id: {eq: "2pVHLoZi1a57Jn5exonJ2j"}) {
       
        title
        contentful_id
        id
        
        description {
          description
        }
        photos {
          id
          title
          gatsbyImageData(
            width: 250
            quality: 70
            aspectRatio: 1.26153846154
            placeholder: NONE
          )
          fullSize: gatsbyImageData(width: 1400, quality: 100, placeholder: NONE)
        }
      }
    }
  `)
  return (
    <Layout pageProps={props}>
      <SEO
        title={'Recently Completed Projects '}
        description={
          'Browse our photo gallery of recently completed projects to find inspiration and contemporary remodeling ideas.'
        }
      />
      <div className='text-center  px-2 lg:px-8 max-w-7xl mx-auto py-12'>
        <h1 className='mx-auto font-bold text-3xl md:text-4xl mb-2'>Recent Projects</h1>
        {data.contentfulPhotoGallery.photos && data.contentfulPhotoGallery.description.description && <p className='text-gray-600 text-sm md:text-base px-4'>
          {data.contentfulPhotoGallery.photos && data.contentfulPhotoGallery.description.description}
        </p>}


        {data &&
          data.contentfulPhotoGallery
          &&
          data.contentfulPhotoGallery.photos ? (
          <PhotoGalleryComponent gallery={data.contentfulPhotoGallery} hideTitle={true} hideDesc={true} />
        ) : null}

      </div>
    </Layout>
  )
}

export default GalleryPage
